import { ProductionActionApiProductionPlansWorkLogIdCancelSplitProductLotPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { INVENTORIES_KEY } from "api/inventories/useInventoriesQuery";
import { WORK_LOG_GET_KEY } from "api/logs/useWorkLogsGetQuery";
import { WORK_KEY } from "api/work/useWorkQuery";
import { ProductionInstance } from "instance/axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";

//삭제
const fetchWorkLogsWorkLogIdCancelSplitLotPut = ({
    workLogId,
}: ProductionActionApiProductionPlansWorkLogIdCancelSplitProductLotPostRequest) =>
    ProductionInstance.productionPlansWorkLogIdCancelSplitProductLotPost({
        workLogId,
    });

export const useWorkLogsWorkLogIdCancelSplitLotPut = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        ({
            workLogId,
        }: ProductionActionApiProductionPlansWorkLogIdCancelSplitProductLotPostRequest) =>
            fetchWorkLogsWorkLogIdCancelSplitLotPut({ workLogId }),
        {
            onSuccess: () => {
                customNotification.success({
                    message: t("파레트 분리가 성공적으로 취소되었습니다."),
                });
                queryClient.invalidateQueries(WORK_KEY)
                queryClient.invalidateQueries(WORK_LOG_GET_KEY);
                queryClient.invalidateQueries(INVENTORIES_KEY);
            },
            onError: (error: any) => {
                customNotification.error({
                    message: error.response.data.message
                });
            }
        }
    );
};
export default useWorkLogsWorkLogIdCancelSplitLotPut;
