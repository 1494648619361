import {
    ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest
} from "@sizlcorp/sizl-api-document/dist/models";
import { GET_INVENTORIES_QUERY_KEY } from "api/inventories/useInventoriesGetQuery";
import { INVENTORIES_KEY } from "api/inventories/useInventoriesQuery";
import { WORK_LOG_GET_KEY } from "api/logs/useWorkLogsGetQuery";
import { WORK_KEY } from "api/work/useWorkQuery";
import { ProductionInstance } from "instance/axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";

interface CancelParams extends ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest { }

type MutationType = "cancel"

type Params<T extends MutationType> = T extends "cancel" ? CancelParams : never;

const cancelInputFetcher = (params: CancelParams) =>
    ProductionInstance.workLogsWorkLogIdCancelInputPut(params);

const useCancelInputPutMutation = <T extends MutationType>(type: T) => {

    const queryClient = useQueryClient();
    const { t } = useTranslation();
    
    const cancelMutation = useMutation(
        (params: Params<typeof type>) => cancelInputFetcher(params as CancelParams),
        {
            onSuccess: () => {
                customNotification.success({
                    message: t("투입이 성공적으로 취소되었습니다."),
                });
                queryClient.invalidateQueries(WORK_KEY)
                queryClient.invalidateQueries(WORK_LOG_GET_KEY);
                queryClient.invalidateQueries(GET_INVENTORIES_QUERY_KEY);
                queryClient.invalidateQueries(INVENTORIES_KEY);
            },
            onError: (error: any) => {
                customNotification.error({
                    message: error.response.data.message
                });
            }
        }
    );

    if (type === "cancel") {
        return cancelMutation;
    } else throw new Error("Invalid mutation type");
};

export default useCancelInputPutMutation;
