import { Badge, Button, Flex, Pagination } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconTrash } from "@tabler/icons-react";
import useWorkLogsWorkLogIdCancelDefectPut from "api/cancelDefect/useWorkLogsWorkLogIdCancelDefectPut";
import useWorkLogsWorkLogIdCancelPerformancePut from "api/cancelPerformance/useCancelPerformance";
import useCancelInputPutMutation from "api/input/useCancelInputPutMutation";
import { useWorkLogsGet } from "api/logs/useWorkLogsGetQuery";
import { BaseText } from "components/common/Text/baseText";
import { WorkTable } from "components/common/table/workTable";
import { BaseTitle } from "components/common/title/BaseTitle";
import { getColorOfLogType, getKoreanWorkLogType } from "constants/workLogType";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormButtonBox } from "../FormButtonBox";
import { FormMain } from "../FormMain";
import { FormWrapper } from "../FormWrapper";
import { LogsFormProps } from "./types";
import useWorkLogsWorkLogIdCancelCorrectionPut from "api/cancelCorrection/useWorkLogsWorkLogIdCancelCorrectionPut";
import useWorkLogsWorkLogIdCancelSplitLotPut from "api/cancelSplitLot/useWorkLogsWorkLogIdCancelSplitLotPut";


const Logs = Object.assign({}, FormMain, {
    Button: Button,
    Description: BaseTitle,
    Text: BaseText,
    Table: WorkTable,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Pagination: Pagination
});

export function LogsForm(params: LogsFormProps) {

    const { workId } = params;
    const [activePage, setPage] = useState<number>(1);

    const { mutate: cancelPerformance } = useWorkLogsWorkLogIdCancelPerformancePut()
    const { mutate: cancelInput } = useCancelInputPutMutation("cancel");
    const { mutate: cancelDefect } = useWorkLogsWorkLogIdCancelDefectPut();
    const { mutate: cancelCorrection } = useWorkLogsWorkLogIdCancelCorrectionPut();
    const { mutate: cancelSplitLot } = useWorkLogsWorkLogIdCancelSplitLotPut();
    const { t } = useTranslation();
    
    const { data: WorkLog } = useWorkLogsGet({
        query: {
            $and: [{ workId: { $eq: workId ?? 0 } },
            { parentWorkLogId: { $eq: null } },
            {
                $or: [
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT } },
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT } },
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE } },
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.RECYCLE } },
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.SPLIT_LOT } },
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.CORRECTION } },
                ],
            },
            {
                $or: [
                    { description: { $ne: 'ACTION' } },
                    { description: { $eq: null } }
                ]
            },
            ]
        },
        sort: "-id",
        page: activePage,
    })

    const onCancel = ({ workLogId, logType }: { workLogId: number, logType: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum }) => {

        if (!window.confirm(t('해당 작업을 취소하시겠습니까?'))) {
            return;
        }

        switch (logType) {
            case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE:
                cancelPerformance({
                    workLogId: workLogId,
                    workLogsWorkLogIdCancelInputPutRequest: {
                        createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                break;
            case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT:
                cancelDefect({
                    workLogId: workLogId
                })
                break;
            case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT:
                cancelInput({
                    workLogId: workLogId
                })
                break;
            case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.CORRECTION:
                cancelCorrection({
                    workLogId: workLogId
                })
                break;
            case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.SPLIT_LOT:
                cancelSplitLot({
                    workLogId: workLogId,
                })
                break;
            default:
                break;
        }
    }

    return (
        <Logs.Wrapper>
            <Logs.Description c={'#010000'} size={'sm'} fw={400}>
                {t('작업 로그(투입, 실적, 불량)를 확인합니다.')}
            </Logs.Description>
            <Logs.Description c={'#000000'} size={'sm'} fw={400}>
                {t('취소 버튼을 클릭하여 특정 작업을 취소할 수 있습니다.')}
            </Logs.Description>
            <Logs.Table
                fontSize={'sm'}
                withBorder={false}
                withColumnBorders={false}
                theadColor={"#FFFFFF"}
                flex={[1, 1, 2, 1, 2, 1, 1, 1]}
                trColor={"#000000"}
                columns={[
                    t("로그 번호"),
                    t("연관 로그 번호"), 
                    t("일시"),
                    t("타입"), 
                    t("로트명"), 
                    t("수량"), 
                    t("작업자"), 
                    t("액션")
                ]}
                data={WorkLog?.rows?.map((log) => {

                    const logType = getKoreanWorkLogType(log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum)
                    const colorOfLogType = getColorOfLogType(log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum)
                    const lotName = (log?.workLogType === "INPUT" || log.workLogType === "RECYCLE")
                        ? (log.logData?.incoming?.lotData?.name !== null ? log.logData?.incoming?.lotData?.name : '-')
                        : undefined;

                    return [
                        <Logs.Text align="right" c={'dark'} size={'xl'}>{log?.id ?? '-'}</Logs.Text>,
                        <Logs.Text c={'dark'} size={'xl'}>{log.parentWorkLogId ?? '-'}</Logs.Text>,
                        <Logs.Text c={'dark'} size={'xl'}>{dayjs(log?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Logs.Text>,
                        <Badge size="xl" color={colorOfLogType}>{t(logType)}</Badge>,
                        <Logs.Text c={'dark'} size={'xl'}>{lotName}</Logs.Text>,
                        <Logs.Text align="right" c={'dark'} size={'xl'}>{log?.quantity ? `${log?.quantity} ${log.unitText}` : '-'}</Logs.Text>,
                        <Logs.Text c={'dark'} size={'xl'}>{log?.creatorUserName}</Logs.Text>,
                        log.alreadyCanceledWorkLog ?
                            <Logs.Text c={'grape'} size={'xl'} ta={'center'}>{t("취소됨")}</Logs.Text>
                            :
                            <Logs.Button
                                bg={"inherit"}
                                styles={{
                                    'root': {
                                        '&:hover': {
                                            background: 'inherit'
                                        }
                                    }
                                }}
                                onClick={() => onCancel({
                                    workLogId: log.id as number,
                                    logType: log.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
                                })}>

                                <IconTrash color="red" />
                                <Logs.Text pl={'1rem'} c={'red'} size={'xl'}>{t("취소")}</Logs.Text>
                            </Logs.Button>
                    ] as string[] | React.ReactElement[];
                })}
            />
            <Flex justify={'center'}>
                <Logs.Pagination
                    size={'xl'}
                    value={activePage}
                    onChange={setPage}
                    total={WorkLog?.totalPages as number}
                />
            </Flex>
        </Logs.Wrapper>
    );
}
